<!-- =========================================================================================
    File Name: CarouselNavigation.vue
    Description: Carousel with navigations
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <!-- <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="(image,i) in images" :key="image._id+i" class="slider1">
        <img :src="`${image.imageUrl}`" class="img-slider"/>
      </swiper-slide>
    
    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
  </swiper> -->

    <div class="mainContainer">
    <swiper
      :options="swiperOption"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
      v-if="isImage(images) == 1"
      style="align-self: center; height:auto; width:100%;"
    >
      <swiper-slide class="imageContainer"  v-for="(image,i) in imagesList" :key="image._id+i">
        <img :src="`${image.imageUrl}`" class="image" alt="images" />
      </swiper-slide>

      <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
    </swiper>
    <swiper v-if="isImage(images) == 2">
      <img
        class="image"
        :src="`${images[0].imageUrl}`"
      />
    </swiper>
    <swiper v-if="isImage(images) == 3">
      <img class="image" src="@/assets/images/logo/noCarImage.png" />
      <!-- image to be replace by default empty image later -->
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  props: ["images"],
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      imagesList: this.images,
    };
  },
  methods: {
    isImage(arr) {
      if (arr != null || arr != undefined) {
        if (arr.length > 1) {
          return 1; //have array of images
        } else if (arr.length == 1) {
          return 2; //only one image in images
        } else {
          return 3; //don't have images
        }
      } else {
        return 3;
      }
    },
  },

  components: {
    swiper,
    swiperSlide,
  },
};
</script>
<style scoped>

  .mainContainer {
    margin-left: 10%;
    height:500px; 
    width:80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;

  }

  .imageContainer {
    float: left;
    width: 100%;
    height:auto;
    display: flex;
    justify-content: center;
  }

  .image {
    display:flex;
    align-self: center;
    height:500px; 
    width:100%; 
    object-fit: contain; 
    background-color:white;
  }
  

</style>
